import React from "react"
import { Router } from "@reach/router"

class CodeHandler extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      window.localStorage.clear()
      window.localStorage.setItem("beneficiaryToken", this.props.studentRef)
    }
    window.location = "/"
  }

  render() {
    return <></>
  }
}

const Code = () => (
  <Router>
    <CodeHandler path="/c/:studentRef" />
  </Router>
)

export default Code
